<template>
  <!--  user_group_management.products_page.alert-->
  <page-layout
    :title="$t('user_group_management.all_user_groups_page.title')"
    :sub-title="$t('user_group_management.all_user_groups_page.sub_title')"
    :fixed-footer="true"
  >
    <template #header-actions>
      <base-button
        prepend-icon="ri-add-line"
        type="primary"
        :to="{ name: 'UserGroupAdd' }"
        :label="$t('user_group_management.all_user_groups_page.button_add_group')"
      />
    </template>
    <div class="filters">
      <el-form ref="form" :inline="true" :model="form" @submit.prevent.native="fetchData">
        <base-input
          class="search"
          ref="search"
          prop="search"
          v-model.trim="form.search"
          name="search"
          auto-complete="off"
          :placeholder="
            $t('user_group_management.all_user_groups_page.form_filter.input_search.placeholder')
          "
        >
          <template #prependInput>
            <i class="ri-search-line"></i>
          </template>
        </base-input>
        <el-form-item>
          <el-checkbox v-model="form.inactive">
            {{
              $t('user_group_management.all_user_groups_page.form_filter.checkbox_inactive.label')
            }}
          </el-checkbox>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      id="appUsersTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="{ prop: 'name', order: 'ascending' }"
      row-class-name="table-row clickable"
      @row-click="rowClick"
      style="width: 100%"
    >
      <el-table-column prop="icon" width="36">
        <template>
          <i class="ri-group-line"></i>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="name"
        :label="$t('general.input_label.name')"
        min-width="110"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="extId"
        min-width="130"
        sortable
        :label="$t('general.label.external_id')"
      />
      <el-table-column
        class-name="text-bold"
        prop="members"
        :label="$t('general.label.members')"
        min-width="110"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="description"
        min-width="130"
        sortable
        :label="$t('general.input_label.description')"
      >
      </el-table-column>
      <el-table-column fixed="right" class-name="actions-cell" width="150">
        <template slot-scope="{ row }">
          <base-button
            v-if="row.data.active"
            @click.stop="handleDelete(row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          ></base-button>

          <base-button
            v-else
            :label="$t('general.button_label.activate')"
            type="success"
            size="small"
            @click.stop="handleActivate(row)"
          ></base-button>
          <base-button
            @click.stop="handleEdit(row)"
            size="small"
            prepend-icon="ri-pencil-line"
            :label="$t('general.button_label.edit')"
          ></base-button>
        </template>
      </el-table-column>
    </el-table>

    <template #footer v-if="totalPages > 1">
      <base-pagination v-model="pagination.page" :pages="totalPages" />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { mapActions, mapGetters } from 'vuex'
import { get } from 'lodash'
import TableLoader from '@/mixins/TableLoader'
import { confirmDefaultOptions } from '@/app/helpers'

const defaultSort = { order: 'ascending', prop: 'name' }

export default {
  name: 'UserGroupsViewAll',
  components: {
    PageLayout
  },
  mixins: [TableLoader],
  data () {
    return {
      loading: false,
      form: {
        inactive: false,
        search: ''
      },
      pagination: {
        page: 0,
        size: 15,
        sort: defaultSort
      }
    }
  },
  computed: {
    ...mapGetters({
      allActive: 'userGroup/allActive',
      allInactive: 'userGroup/allInactive',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    filters () {
      return {
        corporateAccountOspId: this.corporateAccount.ospId
      }
    },
    selectedList () {
      return (this.form.inactive ? this.allInactive : this.allActive).filter(
        v => !v.isDefault(this.corporateAccount.extId)
      )
    },
    tableData () {
      return this.selectedList
        .filter(this.filterTable)
        .slice(
          this.pagination.page * this.pagination.size,
          (this.pagination.page + 1) * this.pagination.size
        )
    },
    totalPages () {
      try {
        return Math.ceil((this.allUserGroups.length || 1) / this.pagination.size)
      } catch (e) {
        return 1
      }
    }
  },
  methods: {
    ...mapActions({
      findAllUserGroup: 'userGroup/findAll'
    }),
    async fetchData () {
      this.TableLoader_show()
      await this.findAllUserGroup(this.corporateAccount.ospId)
      this.TableLoader_hide()
    },
    handleEdit ({ ospId }) {
      this.$router.push({
        name: 'UserGroupDetails',
        params: { userGroupOspId: ospId }
      })
    },
    handleActivate ({ ospId }) {
      this.TableLoader_show()
      this.$api.userGroup.activate(ospId, {
        onSuccess: () => {
          this.fetchData()
        },
        onError: () => {
          this.TableLoader_hide()
        }
      })
    },
    handleDelete ({ ospId }) {
      this.$confirm(
        this.$t('user_group_management.all_user_groups_page.confirm_delete.explanation'),
        this.$t('user_group_management.all_user_groups_page.confirm_delete.title'),
        {
          ...confirmDefaultOptions,
          confirmButtonText: this.$t(
            'user_group_management.all_user_groups_page.confirm_delete.submit_button'
          ),
          confirmButtonClass: 'base-button el-button--danger'
        }
      ).then(() => {
        this.TableLoader_show()
        this.$api.userGroup.delete(ospId, {
          onSuccess: () => {
            this.fetchData()
          },
          onError: () => {
            this.TableLoader_hide()
          }
        })
      })
    },
    rowClick ({ ospId }) {
      this.$router.push({
        name: 'UserGroupMembers',
        params: { userGroupOspId: ospId }
      })
    },
    filterTable (value) {
      const description = value.description.toLowerCase()
      const name = value.name.toLowerCase()
      const search = get(this.form, 'search', '').toLowerCase()
      return description.indexOf(search) > -1 || name.indexOf(search) > -1
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style lang="scss"></style>
